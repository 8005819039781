import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'

class ProductCard extends Component {

  render() {
    let { title, slug, price, image } = this.props
    return (
      <div className="blogs__item product">
        <Fade bottom distance="40px">
          <div className="product__image">
            <img src={image} alt={title} />
          </div>
          <div className="product__wrap">
            <h3>{title}</h3>
            <span className="product__price">From ${price}</span>
          </div>
        </Fade>
        <Link className="blogs__link" to={`/product/${slug}`}></Link>
      </div>
    )
  }
}

ProductCard.defaultProps = {
  title: `TryPositive Book`,
  price: `$20`,
  slug: `/product`,
  image: `https://picsum.photos/820/560?random`,
}

export default ProductCard
